import React from "react";
import data from "./../../utilities/constants";
const loaderItem = () => {
  return (
    <div className="tvdd__ring">
      <div className="tvdd__ring-dots">
        {data.FAKE_SKELETONS.map((_, index) => {
          return <div className="tvdd__ring-dot" key={index}></div>;
        })}
      </div>
    </div>
  );
};

export default loaderItem;
