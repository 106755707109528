import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import data from "./../../utilities/constants";
import { TableCell } from "@mui/material";
const SkeletonRow = (active) => {
  return (
    <>
      {data.FAKE_SKELETONS.slice(
        0,
        active.active === "PendingInvites" ? 5 : 6
      )?.map((_, i) => {
        return (
          <TableCell
            scope="col"
            colSpan="4"
            key={i}
            style={{ border: "none", fontSize: " 1.875rem" }}
          >
            <Skeleton />
          </TableCell>
        );
      })}
    </>
  );
};

export default SkeletonRow;
