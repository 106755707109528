import React from "react";
import {
  Stack,
  Avatar,
  TableRow,
  TableCell,
  Typography,
  Button,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import stringAvatar from "../../utilities/StringToColor";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { revokeOperator, resendInvitation } from "./../../slices/operaters";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { openModal } from "../../slices/modals";
import {
  trashModalIcon,
  EditOperatorIcon,
  DeleteOperatorIcon,
  revoke,
  Shared,
  OperatorProfileImage,
} from "../../assets";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { More } from "./../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const Row = ({ row, active }) => {
  const {
    _id,
    firstName,
    lastName,
    role,
    email,
    avatar,
    availability,
    phone,
    department,
  } = row;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: availability.status === "online" ? "#44b700" : "red",
      color: availability.status === "online" ? "#44b700" : "red",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  }));

  return (
    <TableRow key={_id}>
      <TableCell
        scope="col"
        colSpan="4"
        style={{ wordWrap: "break-word", color: "#91939A" }}
      >
        {_id}
      </TableCell>
      <TableCell scope="col" colSpan="4">
        <Stack direction="row" colSpan={4} alignItems="center" spacing={2}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              src={avatar !== null ? avatar : OperatorProfileImage}
              alt={firstName}
              {...stringAvatar(firstName, lastName)}
            />
          </StyledBadge>
          <Typography variant="subtitle2" noWrap>
            {firstName ? firstName + " " + lastName : "----------------"}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell
        scope="col"
        colSpan="4"
        style={{ wordWrap: "break-word", color: "#91939A" }}
      >
        {email}
      </TableCell>
      <TableCell scope="col" colSpan="4" style={{ color: "#91939A" }}>
        {role?.code}
      </TableCell>
      {active !== "PendingInvites" && (
        <TableCell scope="col" colSpan="4" style={{ color: "#91939A" }}>
          {phone ? phone : "---------------"}
        </TableCell>
      )}
      <TableCell align="right" scope="col" colSpan={4} className="action">
        <div className="btn-menu-wrapper">
          <div id="basic-button" onClick={handleClick}>
            <LazyLoadImage src={More} alt="More" />
          </div>
          <Menu
            id="details-menu"
            anchorEl={anchorEl}
            PaperProps={{
              style: {
                marginTop: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                width: "180px",
                height: "fit-content",
                fontFamily: "Poppins",
              },
            }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            //  getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            {active === "PendingInvites" ? (
              <div>
                <MenuItem
                  className="menu-item"
                  onClick={() => {
                    dispatch(
                      resendInvitation({
                        websiteID: user.websiteID,
                        data: { email: email },
                      })
                    ).then((res) => {
                      !res?.error
                        ? showSnackbar({
                            variant: "success",
                            message: res?.payload?.message,
                          })
                        : showSnackbar({
                            variant: "error",
                            message: res?.error?.message,
                          });
                    });
                    handleClose();
                  }}
                >
                  <LazyLoadImage
                    src={Shared}
                    alt="Shared"
                    style={{ width: "17px", height: "25px" }}
                  />
                  <span> Resend</span>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  style={{ width: "25px", height: "25px" }}
                  onClick={() => {
                    dispatch(
                      revokeOperator({
                        email: email,
                        websiteID: user.websiteID,
                      })
                    ).then((res) => {
                      !res?.error
                        ? showSnackbar({
                            variant: "success",
                            message: res?.payload?.message,
                          })
                        : showSnackbar({
                            variant: "error",
                            message: res?.error?.message,
                          });
                    });
                    handleClose();
                  }}
                >
                  <LazyLoadImage src={revoke} alt="revoke" />
                  <span>Revoke</span>
                </MenuItem>
              </div>
            ) : (
              <div>
                {" "}
                <MenuItem
                  onClick={() => {
                    dispatch(openModal("update-operator", row));
                    handleClose();
                  }}
                  className="menu-item"
                >
                  <LazyLoadImage
                    src={EditOperatorIcon}
                    alt="EditOperatorIcon"
                  />
                  {t("Edit")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(
                      openModal("delete-modal", {
                        operatorId: _id,
                        websiteId: user.websiteID,
                        id: "delete-operator",
                        icon: trashModalIcon,
                        title: t("are you sure to delete this operator?"),
                        description: (
                          <React.Fragment>
                            {t("by clicking on delete this operator will")}
                            <span>&nbsp;{t("Permanently")}&nbsp;</span>
                            {t(
                              "deleted and you will not be able to recover it!"
                            )}
                          </React.Fragment>
                        ),
                      })
                    );

                    handleClose();
                  }}
                  className="menu-item"
                >
                  {" "}
                  <LazyLoadImage src={DeleteOperatorIcon} alt="trashIcon" />
                  {t("Delete")}
                </MenuItem>
              </div>
            )}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Row;
