import React from "react";
import data from "./../../utilities/constants";
import LoaderItem from "./loaderItem";
const Loader = () => {
  return (
    <div className="tvdd" role="img">
      {data.FAKE_SKELETONS.slice(0, 3).map((_, index) => {
        return <LoaderItem key={index} />;
      })}
    </div>
  );
};

export default Loader;
