import React from "react";
import { TableRow, TableCell, TableHead } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function UserListHead({ headLabel, operators }) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            colSpan={operators.length !== 0 ? 4 : 4}
            sx={{ py: 3 }}
            align={headCell.alignRight ? "right" : "left"}
            className="table-header"
          >
            {t(headCell.label)}
          </TableCell>
        ))}
        <TableCell
          align={"center"}
          className="action-column"
          scope="col"
          sx={{ py: 3 }}
          colSpan={4}
        >
          {t("Action")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
