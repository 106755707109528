import React from "react";
import ReactPaginate from "react-paginate";
import "bootstrap/scss/bootstrap.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
export default function PaginatedItems({ count, page, setPage, status }) {
  const { i18n, t } = useTranslation();
  return (
    <ReactPaginate
      pageCount={count}
      pageRangeDisplayed={5}
      marginPagesDisplayed={0}
      forcePage={page - 1}
      onPageChange={(newPage) => {
        setPage(newPage.selected + 1);
      }}
      containerClassName="pagination "
      activeClassName="active"
      pageLinkClassName={
        i18n.language === "ar" ? "page-link arabic-page-item" : "page-link"
      }
      breakLinkClassName={
        i18n.language === "ar" ? "page-link arabic-page-item" : "page-link"
      }
      nextLinkClassName={
        i18n.language === "ar" ? "page-link arabic-page-item" : "page-link"
      }
      previousLinkClassName={
        i18n.language === "ar" ? "page-link arabic-page-item" : "page-link "
      }
      pageClassName={
        i18n.language === "ar" ? "page-item arabic-page-item" : "page-item"
      }
      breakClassName={
        i18n.language === "ar" ? "page-item arabic-page-item" : "page-item"
      }
      nextClassName={
        status === "loading"
          ? "fetch-btn-disabled"
          : i18n.language === "ar"
          ? "page-item arabic-page-item"
          : "page-item"
      }
      previousClassName={
        status === "loading"
          ? "fetch-btn-disabled"
          : i18n.language === "ar"
          ? "page-item arabic-page-item"
          : "page-item"
      }
      previousLabel={
        i18n.language === "ar" ? (
          <>
            <KeyboardArrowLeftIcon />
            {t("Previous")}
          </>
        ) : (
          <>
            <KeyboardArrowLeftIcon />
            {t("Previous")}
          </>
        )
      }
      nextLabel={
        i18n.language === "ar" ? (
          <>
            {t("Next")}
            <ChevronRightIcon />
          </>
        ) : (
          <>
            {t("Next")}
            <ChevronRightIcon />
          </>
        )
      }
    />
  );
}
