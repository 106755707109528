import React from "react";
import { Table, TableRow, TableBody, TableCell } from "@mui/material";
import Row from "./Row";
import SearchNotFound from "./../SearchNotFound";
import UserListHead from "./memberListHead";
import data from "./../../utilities/constants";
import SkeletonRow from "./SkeletonRow";
import TablePagination from "./TablePagination";
import { useSelector } from "react-redux";
export default function MembersTable({
  active,
  operators,
  handleChangePage,
  filterName,
  handleChangeRowsPerPage,
  rowsPerPage,
  setPage,
  page,
  meta,
}) {
  const isUserNotFound = operators?.length === 0;
  const { status } = useSelector((state) => state.operators);
  return (
    <div id="table-container">
      <Table style={{ tableLayout: "fixed" }}>
        <UserListHead
          operators={operators}
          headLabel={
            active === "PendingInvites"
              ? data.PENDING_TABLE_HEAD
              : data.TABLE_HEAD
          }
        />
        <TableBody>
          {status === "loading" ? (
            <SkeletonRow active={active} />
          ) : status === "succeeded" &&
            operators.length === 0 &&
            filterName.length === 0 ? (
            <TableCell align="center" colSpan={20} sx={{ py: 3 }}>
              <span style={{ color: "red" }}> There's No Users</span>
            </TableCell>
          ) : (
            operators.map((row) => {
              return <Row row={row} key={row._id} active={active} />;
            })
          )}
        </TableBody>
        {isUserNotFound && filterName.length !== 0 && (
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                colSpan={active !== "PendingInvites" ? 24 : 20}
                sx={{ py: 3 }}
              >
                <SearchNotFound searchQuery={filterName} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <TablePagination
        count={meta.totalPages}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
